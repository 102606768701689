import { Button } from 'components/button/button';
import { TextInputOutline } from 'components/text-input/text-input';
import userIcon from 'libs/shared/assets/icons/user-icon.svg';
import emailIcon from 'libs/shared/assets/icons/email-icon.svg';
import callIcon from 'libs/shared/assets/icons/call-icon.svg';
import styles from './callback-request.module.scss';
export interface CallbackRequestProps {
  form: {
    email: {
      value: string;
    };
    name: {
      value: string;
    };
    phone: {
      value: string;
    };
    checkBox: {
      checked: boolean;
    };
  };
  onSubmit: () => void;
  onChange: (value: string | boolean, key: string) => void;
}

export const CallbackRequest = ({
  form,
  onSubmit,
  onChange,
}: CallbackRequestProps) => {
  return (
    <div className={styles['card']}>
      <h4 className={styles['title']}>Request Call Back</h4>
      <TextInputOutline
        value={form.name.value}
        onChange={(event) => {
          onChange(event.target.value, 'name');
        }}
        placeholder={'enter name'}
        imgSrc={userIcon}
      />
      <TextInputOutline
        placeholder={'enter email address'}
        value={form.email.value}
        onChange={(event) => {
          onChange(event.target.value, 'email');
        }}
        imgSrc={emailIcon}
      />
      <TextInputOutline
        placeholder={'enter mobile number'}
        value={form.phone.value}
        onChange={(event) => {
          onChange(event.target.value, 'phone');
        }}
        imgSrc={callIcon}
      />
      <div>
        <input
          type='checkbox'
          className={styles['form-check-input']}
          id='exampleCheck1'
          value={`${form.checkBox.checked}`}
          checked={form.checkBox.checked}
          onChange={(event) => {
            onChange(event.target.checked, 'checkBox');
          }}
        />
        <label className={styles['form-check-label']} htmlFor='exampleCheck1'>
          Allow GMP to send notifications
        </label>
      </div>
      <p className={styles['sub-title']}>
        You will recieve notifications via SMS, Whatsapp, Email. You can change
        your preference later.
      </p>
      <Button buttonType={'tertiary'} onClick={onSubmit}>
        Request Call Back
      </Button>
    </div>
  );
};
