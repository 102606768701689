import { useState } from 'react';
import styles from './select.module.scss';
import { ClickAwayListener } from '@mui/material';
import { SelectProps, OptionProps } from 'libs/shared/models';

export const Select: React.FC<SelectProps> = ({
  label,
  selectedValue,
  options = [],
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const displayValue =
    ((selectedValue as OptionProps)?.name as string) ||
    (selectedValue as string);
  const selectedId =
    ((selectedValue as OptionProps)?.id as string) || (selectedValue as string);
  return (
    <div>
      <label className={styles['label']}>{label}</label>
      <ClickAwayListener
        onClickAway={() => {
          if (open) setOpen((prev) => !prev);
        }}>
        <div
          className={`${styles['nice-select']} ${styles['form-control']} ${
            open ? styles['open'] : ''
          }`}>
          <span
            className={`${styles['current']}`}
            onClick={() => setOpen((prev) => !prev)}>
            {displayValue}
          </span>
          <ul className={styles['list']}>
            {options.map((option) => {
              const value: string =
                ((option as OptionProps)?.name as string) || (option as string);
              const id: string =
                ((option as OptionProps)?.id as string) || (option as string);
              return (
                <li
                  onClick={() => {
                    onChange(option);
                    setOpen(false);
                  }}
                  key={id}
                  data-value={id}
                  data-display={value}
                  className={`${styles['option']} ${
                    id === selectedId
                      ? `${styles['selected']} ${styles['focus']}`
                      : ''
                  }`}>
                  {value}
                </li>
              );
            })}
          </ul>
        </div>
      </ClickAwayListener>
    </div>
  );
};
