import { Grid } from '@mui/material';
import styles from './footer.module.scss';
import FooterLogo from 'libs/shared/assets/images/footer-logo.png';
export const Footer = () => {
  const realEstateLinks = [
    { title: 'Bangalore', link: '/property-in-bangalore' },
    { title: 'Chennai', link: '/property-in-chennai' },
    { title: 'Kolkata', link: '/property-in-Kolkata' },
    { title: 'Delhi', link: '/property-in-Delhi' },
    { title: 'Pune', link: '/property-in-Pune' },
    { title: 'Mumbai', link: '/property-in-Mumbai' },
  ];
  const propertiesLinks = [
    { title: 'Godrej Properties', link: '/property-in-Godrej-Properties' },
    { title: 'Sobha Developers', link: '/property-in-Sobha-Developers' },
    { title: 'Mantri Developers', link: '/property-in-Mantri-Developers' },
    { title: 'Unishire Group', link: '/property-in-Unishire-Properties' },
    { title: 'Puravankara Group', link: '/property-in-Puravankara-Properties' },
    {
      title: 'Salarpuria Sattva Group',
      link: '/property-in-Salarpuria-Sattva-Group',
    },
  ];
  return (
    <footer className={styles['footer-container']}>
      <div className={styles['container']}>
        <Grid
          container
          columnSpacing={{ md: 4, sm: 0 }}
          rowSpacing={3}
          justifyContent='center'>
          <Grid item lg={2} md={3} sm={12}>
            <div className={styles['footer-img']}>
              <img src={FooterLogo} className='img-fluid' alt='' />
            </div>
            <h4 className={styles['card-title']}>Follow us on</h4>
          </Grid>
          <Grid item lg={4} md={9} sm={12}>
            <p className={styles['text-start']}>
              Getmyprop.com is India's premium real estate portal where users
              can find Independent Houses / Villas, Apartments/Flats and Plots
              for sale and rent in 400+ cities across India. Getmyprop helps the
              property seeker to find Resale properties, Residential Projects
              and Owner properties in Bangalore, Gurgaon,Noida, Chennai,
              Hyderabad, Mumbai, Ahmedabad,Kolkata and other cities.
            </p>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <h4 className={styles['card-title']}>Properties by Developers</h4>
            <ul className={styles['list-style']}>
              {propertiesLinks.map((link) => {
                return (
                  <li key={link.title}>
                    <a href={link.link}>{link.title}</a>
                  </li>
                );
              })}
            </ul>
          </Grid>
          <Grid item lg={3} md={6} sm={12} xs={12}>
            <h4 className={styles['card-title']}>Properties by Location</h4>
            <ul className={styles['list-style']}>
              {realEstateLinks.map((link) => {
                return (
                  <li key={link.title}>
                    <a href={link.link}>{link.title}</a>
                  </li>
                );
              })}
            </ul>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
};
