import styles from './header.module.scss';
import Logo from 'libs/shared/assets/images/logo.png';
import { Button } from 'components/button/button';
import { ReactComponent as ArrowIcon } from 'libs/shared/assets/icons/arrow-right-long-solid.svg';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
export const Header = ({ activeTab = 'Bangalore' }) => {
  const locationList = ['Bangalore', 'Pune', 'Chennai', 'Mumbai', 'Kolkata'];
  const [open, setopen] = useState(false);
  const navigate = useNavigate();
  return (
    <header className={styles['main-container']}>
      <div className={styles['container']}>
        <div className={styles['logo-container-mobile']}>
          <img
            src={Logo}
            alt='logo'
            onClick={() => {
              navigate('/');
            }}
          />
        </div>
        <div className={styles['hamburger-container']} data-open={`${open}`}>
          <div
            className={styles['hamburger-icon']}
            onClick={() => {
              setopen((prev) => !prev);
            }}></div>
          <div className={styles['hamburger-nav-link']}> </div>
        </div>
        <section className={styles['image-container']}>
          <div className={styles['logo-container']}>
            <img
              src={Logo}
              alt='logo'
              onClick={() => {
                navigate('/');
              }}
            />
          </div>
          <div className={styles['nav-links']}>
            <ul>
              {locationList.map((location) => {
                return (
                  <li
                    key={location}
                    className={styles['nav-link']}
                    data-active={`${location === activeTab}`}>
                    <a href={`/property/${location}`}>{location}</a>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={styles['btn-container']}>
            <Button buttonType='primary'>
              Login <ArrowIcon className={styles['svg-icon']} />
            </Button>
          </div>
        </section>
      </div>
    </header>
  );
};
