import { useState } from 'react';
import styles from './text-input.module.scss';
import { ClickAwayListener } from '@mui/material';

export interface TextInputProps {
  label: string;
  prefix?: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  error?: boolean;
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  options?: string[];
  onClick?: (value: string) => void;
}
export interface TextInputOutlineProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorMessage?: string;
  error?: boolean;
  type?: React.HTMLInputTypeAttribute;
  placeholder?: string;
  imgSrc?: string;
}

export const TextInput = ({
  label,
  value,
  onChange,
  type,
  placeholder = '',
  options = [],
  onClick,
}: TextInputProps) => {
  const [open, setOpen] = useState(false);
  return (
    <ClickAwayListener
      onClickAway={() => {
        if (open) setOpen((prev) => !prev);
      }}>
      <div className={styles['nice-select']}>
        <label className={styles['label']}>{label}</label>
        <input
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={styles['form-control']}
          onFocus={() => {
            setOpen(true);
          }}
        />
        {open && value.length > 2 && (
          <ul className={styles['list']}>
            {options.map((option) => {
              return (
                <li
                  onClick={() => {
                    onClick?.(option);
                    setOpen(false);
                  }}
                  key={option}
                  data-value={option}
                  data-display={value}
                  className={`${styles['option']} ${
                    option === value
                      ? `${styles['selected']} ${styles['focus']}`
                      : ''
                  }`}>
                  {option}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </ClickAwayListener>
  );
};

export const TextInputOutline = ({
  value = '',
  onChange,
  type = 'text',
  placeholder = '',
  imgSrc,
}: TextInputOutlineProps) => {
  return (
    <div className={styles['form-group']}>
      <input
        type={type}
        className={styles['form-control']}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <img src={imgSrc} className={styles['img-fluid']} alt='' />
    </div>
  );
};
